import React from 'react';
import Diver from '../resources/diver.png';
import SEO from '@americanexpress/react-seo';

document.onkeydown = function (evt) {
  evt = evt || window.event;
  if (evt.keyCode === 220) {
    window.open(process.env.REACT_APP_ADMIN_BASE_URL + '/add', '_self');
  }
};
class Info extends React.Component {
  render() {
    return (
      <div id="page" className="content-page">
        <SEO
          title="Info - Paikkatietokanta"
          description="Paikkatietokanta yhdistää valokuvaharrastus, historiallinen dokumentointi ja ammatillinen päämäärä kehittyä paremmaksi koodariksi. Sivuston on tarkoitettu henkilökohtaiseen käyttöön."
          locale="fi_FI"
          siteUrl={process.env.REACT_APP_BASE_URL + '/info/'}
          image={{
            src: process.env.REACT_APP_BASE_URL + '/logo512.png',
          }}
          openGraph={{
            title: 'Info - Paikkatietokanta',
            description: 'Paikkatietokanta yhdistää valokuvaharrastus, historiallinen dokumentointi ja ammatillinen päämäärä kehittyä paremmaksi koodariksi. Sivuston on tarkoitettu henkilökohtaiseen käyttöön.',
            type: 'article',
            siteName: 'Paikkatietokanta',
            url: process.env.REACT_APP_BASE_URL + '/info/',
            locale: 'fi_FI',
            image: {
              src: process.env.REACT_APP_BASE_URL + '/logo512.png',
              alt: 'Info - Paikkatietokanta',
            },
          }}
        />
        <div className="innerwidth">
          <div className="innercontainer">

            <div className="row">
              <div className="col-sm">
                <h2>Tietoa paikkatietokannasta</h2>
                <p>
                  Paikkatietokanta on <a href="http://penttinen.fi/">Jani Penttisen</a> päättöprojektina syntynyt sivusto. Paikkatietokannan tarkoitus on yhdistää valokuvaharrastus, 
                  historiallinen dokumentointi ja ammatillinen päämäärä kehittyä paremmaksi koodariksi.
                </p>

                <p>
                  Sivusto on rakennettu käyttämällä: <br />
                  <code>HTML5, SASS, MySQL, React, Node</code> ja palju <code>kohvi.</code>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Info;